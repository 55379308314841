function playAnimationStep($selector, animName) {
	const playingClass = `anim-${animName}`;
	const completeClass = `anim-${animName}-complete`;
	return new Promise((res, rej) => {
		$selector.addClass(playingClass);
		$selector.removeClass('animated');
		$selector.addClass('animating');
		$selector.on('transitionend', () => {
			$selector.removeClass(playingClass);
			$selector.addClass(completeClass);

			$selector.removeClass('animating');
			$selector.addClass('animated');
			requestAnimationFrame(res);
		});
	});
}

function delay(duraation) {
	return new Promise(res => {
		setTimeout(res, duraation);
	});
}

$(function () {
	if ($(window).width() < 1025) return;
	const mouseData = { x: 0, y: 0, radius: 0, rotation: 0, overSelector: false };
	const $selector = $('.splash-select');

	$selector.one('unbind', function () {
		$selector.off('mouseover mousemove mouseleave');
	});

	$selector.one('click', '.splash-action', function (e) {
		const $target = $(this);
		//const $other = $target.siblings('.splash-action').first();
		const targetUrl = $target[0].href;

		//console.log(targetUrl);

		//e.preventDefault();

		//$('.splash-skip-message').addClass('show');

		//$selector.trigger('unbind');

		//await playAnimationStep($selector, 'hide-logos');
		//await playAnimationStep($selector, 'expand');
		//await playAnimationStep($selector, 'reveal-message');
		//await delay(250);

		window.location.href = targetUrl;
	});

	$selector.on('mouseover mousemove', function (e) {
		const $target = $(e.target).closest('.splash-action');
		const $other = $target.siblings('.splash-action');
		$other.removeClass('active');
		$target.addClass('active');
		mouseData.overSelector = true;
	});

	$selector.on('mouseleave', function (e) {
		$(this).find('.splash-action').removeClass('active');
		mouseData.overSelector = false;
	});

	// region Handle Mouse
	const mask = $('.splash-cursor-mask')[0];
	const useFancy = window.getComputedStyle(mask, null).display === 'flex';

	$(document).on('mousemove', function (e) {
		mouseData.x = e.clientX;
		mouseData.y = e.clientY;
	});

	requestAnimationFrame(function drawLoop() {
		const width = $selector.find('.splash-left').width();
		const onRightSide = mouseData.x > width;
		const radiusLimit = 50;
		const radiusGrowthSpeed = 10;
		const rotationLimit = 60;
		const rotationSpeed = 5;

		if (mouseData.overSelector) {
			if (mouseData.radius < radiusLimit) {
				mouseData.radius += radiusGrowthSpeed;
			}
		} else {
			if (mouseData.radius > 0) {
				mouseData.radius -= radiusGrowthSpeed;
			}
		}

		if (onRightSide) {
			if (mouseData.rotation < rotationLimit) {
				mouseData.rotation += rotationSpeed;
			}
		} else {
			if (mouseData.rotation > 0) {
				mouseData.rotation -= rotationSpeed;
			}
		}

		if (useFancy) {
			mask.style.setProperty('--clip-radius', mouseData.radius + 'px');
			mask.style.setProperty('--left-width', width + 'px');
			mask.style.setProperty('--clip-x', mouseData.x + 'px');
			mask.style.setProperty('--clip-y', mouseData.y + 'px');
		} else {
			$(mask).css({
				width: mouseData.radius * 2,
				height: mouseData.radius * 2,
				top: mouseData.y - mouseData.radius,
				left: mouseData.x - mouseData.radius,
				backgroundColor: onRightSide ? '#c10001' : '#3a8896'
			});
		}

		$(mask).find('.splash-cursor-icon').css({
			'transform': `translate(-50%, -50%) rotate(${mouseData.rotation}deg)`
		});

		requestAnimationFrame(drawLoop);
	});
	// endregion
});
